const p = new URLSearchParams(window.location.search).get('gclid');
if (p) {
  localStorage.setItem('easy-expense:gclid', p);
}
const g = localStorage.getItem('easy-expense:gclid');
if (g) {
  [...document.querySelectorAll('a')]
    .filter((e) => e.href.includes('app.easy-expense.com'))
    .forEach((e) => {
      const u = new URL(e.href);
      u.searchParams.set('gclid', g);
      e.href = u.toString();
    });
}
